export default class Participation {
  constructor(model, id, entityId, tableId, relationshipId, cardinality, externalIdentifier, role) {
    this.__type = 'Participation';
    this._model = () => model;
    this._id = id;
    this._entityId = entityId;
    this._tableId = tableId;
    this._relationshipId = relationshipId;
    this._cardinality = cardinality;
    this._externalIdentifier = externalIdentifier;
    this._role = role;
  }
  static fromObject(model, obj) {
    return new Participation(model, obj._id, obj._entityId, obj._tableId, obj._relationshipId, obj._cardinality, obj._externalIdentifier, obj._role);
  }
  getId() {
    return this._id;
  }
  getEntity() {
    return this._entityId ? this._model().getItemById(this._entityId) : null;
  }
  getTable() {
    return this._tableId ? this._model().getItemById(this._tableId) : null;
  }
  getGenericET() {
    return this.getEntity() || this.getTable();
  }
  getRelationship() {
    return this._model().getItemById(this._relationshipId);
  }
  getCardinality() {
    return this._cardinality;
  }
  getMaxCardinality() {
    return this.getCardinality().split('_')[1];
  }
  isExternalIdentifier() {
    return this._externalIdentifier;
  }
  getRole() {
    return this._role;
  }
  getUnaryIndex() {
    const relationship = this.getRelationship();
    if(relationship.isRecursive())
      return relationship.getParticipations().map(p => p.getId()).indexOf(this.getId());
    else
      return null;
  }
  getSupportedFunctionalities() {
    return {
      externalIdentifier: true,
      cardinality: true,
      cardinalityN: true,
      role: true
    };
  }
  getAllowedFunctionalities() {
    let externalIdentifier = { ok: true };
    /*if(this.getEntity()?.hasKey())
      externalIdentifier = { ok: false, error: 'The involved entity already has internal identifiers.' };*/
    if(this.getRelationship().isRecursive())
      externalIdentifier = { ok: false, error: 'External identification is not possible with recursive relationships.' };

    let cardinality = { ok: true };
    if(this.isExternalIdentifier())
      cardinality = { ok: false, error: 'For an externally-identified entity, the cardinality of the participation is implicitly (1,1).' };

    return {
      externalIdentifier,
      cardinality
    };
  }
  __beforeDelete() {
    if(!this.getEntity()?.getParticipations().some(p => p.isExternalIdentifier()))
      this.getEntity()?.resetExternalIdentifierAttributes();
  }
  setCardinality(cardinality) {
    this._cardinality = cardinality;
  }
  setExternalIdentifier(externalIdentifier) {
    this._externalIdentifier = externalIdentifier;
    if(this.isExternalIdentifier())
      this.setCardinality('1_1');
    else if(!this.getEntity().getParticipations().some(p => p.isExternalIdentifier()))
      this.getEntity().resetExternalIdentifierAttributes();
  }
  setRole(role) {
    role = role.replace(/\s/, '_').replace(/[^a-zA-Z0-9_]/, '').replace(/^\d+/, '');
    this._role = role;
    return role;
  }
  toERCode() {
    let code = `${this.getEntity().getName()}`;
    
    if(this.getRole())
      code += ` "${this.getRole()}"`;

    code += ': ' + this.getCardinality().replace('_', '..').replace(/0/g, 'zero').replace(/1/g, 'one').replace(/N/g, 'many');

    if(this.isExternalIdentifier())
      code += ' external';
    
    return code;
  }
}