<template>
  <div class="button-container" v-bind:class="{ disabled }">
    <div class="button" v-bind:class="{ disabled, selected }" @click="$emit('click')">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 80 80" v-html="svg"></svg>
      <div class="icon-with-text">
          <p class="emoji">🔑</p>
          <p>{{ text }}</p>
      </div>
    </div>
    <span class="tooltip" v-if="disabled && errorTooltip">{{ errorTooltip }}</span>
  </div>
</template>

<script>
import { computed } from 'vue';
import icons from '../icons';

export default {
  name: 'ToolboxButton3',
  props: ['text', 'icon', 'errorTooltip', 'disabled', 'selected'],
  emits: ['click'],
  setup(props) {
    const svg = computed(() => icons[props.icon]);
    return {
      svg
    };
  }
};
</script>

<style scoped>
svg {
  width: 100%;
  height: 33px;
  fill: #191716;
}
p {
  margin: 0;
  font-size: 11px;
  text-align: center;
  user-select: none;
  font-family: 'Commissioner', sans-serif;
}
.button-container {
  position: relative;
}
.button-container.disabled {
  cursor: not-allowed;
}
.button-container:hover .tooltip {
  display: block;
}
.button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 68px;
  height: 50px;
  padding: 4px;
  border-radius: 4px;
}
.button.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.4;
}
.button.selected {
  background: #e6af2e;
}
.button:not(.disabled):not(.selected):hover {
  background: #f2dea6;
}
.tooltip {
  display: none;
  position: absolute;
  width: 200px;
  top: 10px;
  left: 75px;
  padding: 6px 10px;
  background: #f0f0f0;
  font-size: 12px;
}
.emoji {
  display: inline-block;
  font-size: 8px;
  filter: brightness(0) saturate(100%);
}
.icon-with-text {
  display: flex;
  justify-content: flex-start;
}
@media all and (max-width: 720px) {
  svg {
    height: 29px;
  }
  p {
    font-size: 10px;
    font-family: 'Commissioner', sans-serif;

  }
  .button {
    width: 66px;
    height: 40px;
    padding: 2px;
    border-radius: 4px;
  }
}
</style>
<style>
.button svg text {
  cursor: pointer;
}
</style>
