export const splitCompositeAttribute = function(model, attributeId) {
  const attribute = model.getItemById(attributeId);
  for(let subattribute of attribute.getSubattributes()) {
    model.addAttribute(
      `${attribute.getName()}_${subattribute.getName()}`,
      attribute.getParent().getId(),
      false,
      false,
      attribute.getCardinality(),
      subattribute.getX() + attribute.getX() / 2,
      subattribute.getY() + attribute.getY() / 2
    );
    model.deleteItem(subattribute.getId());
  }
  model.deleteItem(attribute.getId());
};

export const mergeCompositeAttribute = function(model, attributeId) {
  const attribute = model.getItemById(attributeId);
  for(let subattribute of attribute.getSubattributes()) {
    attribute.setName(`${attribute.getName()}_${subattribute.getName()}`);
    model.deleteItem(subattribute.getId());
  }
};